<template>
  <div class="error-page main-container">
    <div class="container--slim">
      <icon
        class="error-page__icon"
        image="info"
      />

      <h1>{{ $t('error_page_generic_headline') }}</h1>
      <div>{{ error || $t('error_page_generic_text') }}</div>
      <app-button
        v-if="enlistToken && showBack"
        :id="getGtmId('error_back_to_login')"
        arrow
        type="secondary"
        class="error-page__button"
        :label="$t('error_page_back_button_label')"
        @click="goBackToLogin"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Icon from '@/components/common/Icon';
import AppButton from '@/components/common/Button';

export default {
  name: 'Error',

  components: {
    Icon,
    AppButton,
  },

  data: () => ({
    showBack: false,
    error: false,
  }),

  computed: {
    ...mapState(['enlistToken']),
  },

  mounted() {
    this.showBack = this.$route.params.showBack;
    if (this.$route.params.error) this.error = this.$route.params.error;
  },

  methods: {
    ...mapActions(['removeParticipation']),

    async goBackToLogin() {
      await this.removeParticipation();
      this.$router.push({ name: 'Entry', query: { token: this.enlistToken } });
    },
  },
};

</script>

<style lang="scss" scoped>

.error-page {

  &__icon {
    margin: auto;
    width: 100px;
    height: 100px;
    color: $error-red;
  }

  &__button {
    margin: 2rem auto;
  }
}

</style>
